//
//
//
//


export default {
  name: 'PictoArrow',
  props: {
    orientation: {
      type: String,
      default: 'bas'
    }
  }
}
